var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "proxmox-list" },
    [
      _vm.title
        ? _c("div", { staticClass: "proxmox-title" }, [
            _vm.titleAsLink
              ? _c(
                  "a",
                  {
                    staticClass: "proxmox-link",
                    attrs: { href: _vm.clusterUrl, target: "_blank" },
                  },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                )
              : _vm._e(),
            !_vm.titleAsLink
              ? _c("span", [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._l(_vm.data, function (item, key) {
        return _c("div", { key: key, staticClass: "proxmox-row" }, [
          item.node
            ? _c("div", { staticClass: "proxmox-cell" }, [
                _vm._v(_vm._s(item.node)),
              ])
            : _vm._e(),
          item.name
            ? _c("div", { staticClass: "proxmox-cell" }, [
                _vm._v(_vm._s(item.name)),
              ])
            : _vm._e(),
          _c("div", { staticClass: "proxmox-cell proxmox-status" }, [
            _c("span", { class: item.status }),
          ]),
        ])
      }),
      _vm.footer
        ? _c("div", { staticClass: "proxmox-footer" }, [
            _vm.footerAsLink
              ? _c(
                  "a",
                  {
                    staticClass: "proxmox-link",
                    attrs: { href: _vm.clusterUrl, target: "_blank" },
                  },
                  [_vm._v(" " + _vm._s(_vm.footer) + " ")]
                )
              : _vm._e(),
            !_vm.footerAsLink
              ? _c("span", [_vm._v(_vm._s(_vm.title))])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }